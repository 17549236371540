import { Controller } from '@hotwired/stimulus';

export default class FilterController extends Controller {
  static targets = ['sortForm', 'filterForm', 'elementContainer', 'rangeInput'];

  updateExpressions() {
    this.expressions = Object.fromEntries(
      this.filterFormTarget.dataset.expressions.split(' ').map((expression) => {
        const parts = expression.split(':', 2);
        return [parts[0], parts[1]];
      })
    );
  }

  disconnect() {
    this.elementContainerTarget.replaceChildren(...this.elements);
    this.elements = null;
  }

  elementContainerTargetConnected() {
    this.updateExpressions();
    this.elements = Array.from(this.elementContainerTarget.children);
    this.update();

    this.rangeInputTargets.forEach((input) => {
      const attribute = input.name;
      const values = this.elements.map((element) => element.dataset[attribute]);
      const max = Math.max(...values);
      const min = Math.min(...values);

      input.max = max;
      input.min = min;
      input.value = max;
    });
  }

  update() {
    const order = this.order();
    const sortedElements = this.filteredElements().sort(
      (a, b) => a.dataset[order] - b.dataset[order]
    );

    this.elementContainerTarget.replaceChildren(...sortedElements);
  }

  filteredElements() {
    const filters = this.filters();

    return this.elements.filter((element) => (
      filters.every((filter) => {
        if (filter.value === '') { return true; }

        const attributeValue = element.dataset[filter.attribute];

        switch (this.expressions[filter.attribute]) {
          case 'lte':
            return parseInt(attributeValue, 10) <= parseInt(filter.value, 10);
          default: // Default is equals
            return attributeValue === filter.value;
        }
      })
    ));
  }

  filters() {
    const filterData = new FormData(this.filterFormTarget);
    const order = this.order();
    const activeFilters = Array.from(
      new Set(
        Array.from(this.filterFormTarget.elements)
          .filter((element) => (
            element.dataset.whenOrderedBy == null
              || element.dataset.whenOrderedBy === order
          ))
          .map((element) => element.name)
      )
    );
    return activeFilters.map((key) => (
      { attribute: key, value: filterData.getAll(key).pop() }
    ));
  }

  order() {
    return (new FormData(this.sortFormTarget)).get('order');
  }
}
