import { Controller } from '@hotwired/stimulus';

export default class ClassSwitcherController extends Controller {
  static targets = ['switch'];
  static values = { state: Boolean };

  connect() {
    this.updateSwitchTargets();
  }

  switchTargetConnected(target) {
    this.updateSwitchTarget(target);
  }

  toggle() {
    this.stateValue = !this.stateValue;

    this.updateSwitchTargets();
  }

  stateValueChanged() {
    this.updateSwitchTargets();
  }

  updateSwitchTargets() {
    this.switchTargets.forEach((target) => this.updateSwitchTarget(target));
  }

  updateSwitchTarget(target) {
    if (this.stateValue) {
      target.className = `${target.dataset.commonClass} ${target.dataset.activeClass}`;
    } else {
      target.className = `${target.dataset.commonClass} ${target.dataset.inactiveClass}`;
    }
  }
}
