export default class AutocompleteSource {
  constructor(requestUrl, callback) {
    this.requestUrl = requestUrl;
    this.callback = callback;
    this.currentResponse = null;
    this.awaitingQuery = null;
    this.current_query = null;
    this.data = null;
  }

  get query() {
    return this.current_query;
  }

  set query(query) {
    this.current_query = query;

    this.fetch();
  }

  fetch() {
    if (this.query.length < 2) { return; }

    if (this.currentResponse !== null) {
      this.awaitingQuery = this.query;
      return;
    }

    this.performFetch(this.query);
  }

  // Internal

  async performFetch(query) {
    this.currentResponse = true;
    this.currentResponse = await fetch(`${this.requestUrl}?q=${query}`);
    this.data = await this.currentResponse.json();

    this.callback(this.data);
    this.currentResponse = null;

    if (this.awaitingQuery === null) { return; }

    this.performFetch(this.awaitingQuery);
    this.awaitingQuery = null;
  }
}
