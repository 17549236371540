import { Controller } from '@hotwired/stimulus';

export default class TurboFrameFallbackController extends Controller {
  static targets = ['frame', 'networkErrorContent', 'frameMissingContent'];

  connect() {
    if (this.hasNetworkErrorContentTarget) {
      this.frameTarget.addEventListener('turbo:fetch-request-error', this.handleFetchRequestError.bind(this));
    }
    if (this.hasFrameMissingContentTarget) {
      this.frameTarget.addEventListener('turbo:frame-missing', this.handleFrameMissing.bind(this));
    }
  }

  handleFetchRequestError(event) {
    event.preventDefault();

    this.frameTarget.replaceChildren();
    this.networkErrorContentTarget.style.display = '';
  }

  handleFrameMissing(event) {
    event.preventDefault();

    this.frameTarget.replaceChildren();
    this.frameMissingContentTarget.style.display = '';
  }
}
